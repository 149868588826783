html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

main {
  height: calc(100vh - 64px);
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  list-style: none;
}

.makeStyles-root-12 {
  background-color: transparent !important;
}

.animate-scroll-list {
  /* overflow: auto; */
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  --offset: 0vh;
  --move-initial: calc(0%);
  --move-final: calc(-800%);
}
.animate-scroll-item {
  display: flex;
  transform: translate3d(0, var(--move-initial), 0);
  animation: scroll 30s linear infinite;
  /* animation-play-state: paused; */
}

@keyframes scroll {
  0% {
    transform: translate3d(0, var(--move-initial), 0);
  }

  100% {
    transform: translate3d(0, var(--move-final), 0);
  }
}

/* // scroll bar */
::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background-color: #3f3f3f;
  border-radius: 20px;
  /* // outline: 1px solid slategrey; */
}
